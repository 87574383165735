<template>
    <Folder
        :id="kitItemId"
        :title="kitItemName"
        :color="kitItemColor"
        :width="calculateFolderWidth('kit', folderSize, isMobile)"
        :height="calculateFolderHeight('kit', folderSize, isMobile)"
        :left="getPositionFolderKit(kitItemId, 'left', folderSize)"
        :top="getPositionFolderKit(kitItemId, 'top', folderSize)"
        :isMobile="isMobile"
        @click.native="onClickFolder"
    >
        <template v-slot:panel>
            <FolderControlPanel
                :isFull="!isMobile"
                :isFifty="!isMobile"
                :isColumnsSettings="true"
                @onFullSize="onFullSize"
                @onFiftySize="onFiftySize"
                @onCloseFolder="onCloseFolder"
                @onColumnsSettings="onColumnsSettings"
            />
        </template>
        <template v-slot:content>
            <!-- <TableKit :columns="kitTableColumnsBase"/> -->
            <KitTab
                :isProgressBarLoading="isLoading"
                :folderId="kitItemId"
                :columns="columns"
                :tableData="rows"
                :canCheck="true"
                :rowsSelected="rowsSelected"
                :kitStatusColors="kitStatusColors"
                :style="{'height': '100%'}"
                @filter="onFilterTable"
                @sort="onSortTable"
                @lazyload="onLazyloadTable"
                @onCheckRow="onCheckRowTable"
                @onCheckRowsAll="onCheckRowsAllTable"
                @onSelectStatus="onSelectStatus"
            />
        </template>
    </Folder>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Folder from '../components/Folder/Folder.vue';
import FolderControlPanel from '../components/Folder/FolderControlPanel.vue';
// import TableKit from '../components/Tables/TableKit.vue';
import KitTab from '../components/HandbookComponents/KitTab.vue';
import MixinKitFolder from '@/mixins/MixinsPageBayuerdesk/MixinKitFolder';
import ServiceMapTable from '../services/ServicesMap/ServiceMapTable';
export default {
    name: 'LayoutTablesKit',
    components: {
        Folder,
        FolderControlPanel,
        // TableKit,
        KitTab
    },
    mixins: [MixinKitFolder],
    props: {
        kitItemId: {
            type: String,
            default: ''
        },
        kitItemName: {
            type: String,
            default: ''
        },
        kitItemColor: {
            type: String,
            default: ''
        },
        kitStatusColors: {
            type: Object,
            default: () => ({})
        },
        kitItemFinishedAt: {
            type: [Object, String],
            default: () => null
        }
    },
    data: () => ({
        columns: [],
        rows: [],
        isLoading: false,
        pageCurrent: -1,
        pageCount: 1,
        filter: {},
        sort: {}
    }),
    computed: {
        ...mapState({
            selectedSides: state => state.selection.selectedSides,
            isMobile: state => state.isMobile,
        }),

        rowsSelected() {
            const rowsSelected = this.rows.filter(row => {
                const {id = ''} = row;
                const index = this.selectedSides.findIndex(side => {
                    const {sideId = '', kitItemId = ''} = side;
                    return String(sideId) === String(id) && String(this.kitItemId) === String(kitItemId);
                })
                return index !== -1;
            }).map(row => String(row?.id));

            return rowsSelected;
        },

        tableFilterData() {
            return {
                filter: {...this.filter},
                sort: {...this.sort}
            };
        }
    },
    watch: {
        filter() {
            this.$emit('changeFilterTable', this.kitItemId, this.filter);
        }
    },
    methods: {
        ...mapMutations('folders', ['toggleResizeFolder']),

        async getKitTable() {
            if (this.pageCurrent < this.pageCount - 1) {
                this.isLoading = true;
                this.pageCurrent += 1;
                await ServiceMapTable.getMapTable(this.pageCurrent, this.kitItemId, this.tableFilterData, (rows, columns, pageCount) => {
                    this.columns = [...columns];
                    this.rows.push(...rows);
                    this.pageCount = pageCount;
                });
                this.isLoading = false;
            }
        },

        async editKitTableStatus(statusSelectedData = {}) {
            if (this.kitItemFinishedAt !== null) {
                alert('Вы завершили редактирование, сменить статус нельзя');
            } else {
                const {entityName, attribute, priceId, newValue, rowData, columnProp} = statusSelectedData;
    
                await ServiceMapTable.editMapTableStatus(entityName, priceId, attribute, newValue, (statusData) => {
                    const {id: rowId = ''} = rowData;
                    const newRowData = {
                        ...rowData,
                        [columnProp]: {
                            ...rowData[columnProp],
                            value: String(statusData?.value ?? ''),
                            defaultTitle: String(statusData?.label ?? '')
                        }
                    };
    
                    const rowIndex = this.rows.findIndex(row => String(row?.id) === String(rowId));
                    this.rows.splice(rowIndex, 1, newRowData);
    
                    this.$emit('onSelectStatus', statusSelectedData, this.kitItemId);
                });
            }
        },

        onSelectStatus(statusSelectedData = {}) {
            this.editKitTableStatus(statusSelectedData);
        },

        onColumnsSettings() {
            this.$emit('onColumnsSettings');
        },

        onFilterTable(dataFilter = {}) {
            this.filter = {...dataFilter};

            this.onFilterTableAfter();
        },

        onSortTable(dataSort = {}) {
            const {type = '', key = ''} = dataSort;
            this.sort = {
                attribute: String(key),
                value: String(type)
            };

            this.onFilterTableAfter();
        },

        onFilterTableAfter() {
            this.clearData(false);
            this.getKitTable();
        },

        onLazyloadTable() {
            this.getKitTable();
        },

        onCheckRowTable(checkData = {}) {
            const {row = {}} = checkData;
            const {id: rowId = '', extraData: {marker_id: markerId = ''} = {}} = row;
            this.$emit('onCheckRowTable', this.kitItemId, String(rowId), String(markerId));
        },

        // eslint-disable-next-line no-unused-vars
        onCheckRowsAllTable(isSelected = false) {
            const rows = this.rows.filter(row => isSelected ? !this.rowsSelected.includes(String(row?.id)) : this.rowsSelected.includes(String(row?.id)));
            this.$emit('onCheckRowsAllTable', this.kitItemId, rows);
        },

        onClickFolder() {
            this.bringToFront(this.kitItemId);
        },

        /**
         * Расширить на все окно
         */
        onFullSize() {
            this.toggleResizeFolder({ id: this.kitItemId, resize: 'full' });
        },

        /**
         * Расширить на половину ширины окна
         */
        onFiftySize() {
            this.toggleResizeFolder({ id: this.kitItemId, resize: 'fifty' });
        },

        onCloseFolder() {
            this.toggleFolder(this.kitItemId);
            this.clearData(false);
            this.$emit('onCloseTable', this.kitItemId);
        },

        toggleTable() {
            this.toggleFolder(this.kitItemId, 'custom');

            if (!this.kitsFolderIdsActive.includes(this.kitItemId))
                this.clearData(false);
            else
                this.getKitTable();
        },

        updateTable() {
            if (this.kitsFolderIdsActive.includes(this.kitItemId)) {
                this.clearData(false);
                this.getKitTable();
            }
        },

        clearData(isClearFilter = true) {
            this.rows = [];
            this.pageCurrent = -1;
            this.pageCount = 1;

            if (isClearFilter) {
                this.filter = {};
                this.sort = {};
            }
        },
    }
}
</script>
