<template>
    <div class="summary-item">
        <button class="summary-item__btn-close icon-tabler-icon-x" @click="onCloseItem"></button>
        <div class="summary-item__title">{{name}}</div>
        <div class="summary-item__title">{{count}} ст</div>
<!--        <div class="summary-item__description">Сводная информация</div>-->
        <SummaryItemGroup
            :group="price"
        />

        <SummaryItemGroup
            :group="budget"
        />

        <div
            v-for="(group, index) in groups"
            :key="index"
        >
            <SummaryItemGroup
                :group="group"
            />
        </div>
    </div>
</template>

<script>
    import SummaryItemGroup from "./SummaryItemGroup";
    export default {
        name: "SummaryItem",
        components: {
            SummaryItemGroup
        },
        /**
         * @return {Object} summary сводные данные для группы или выбранного
         */
        props: {
            summary: {
                type: Object,
                default: () => {},
            }
        },
        data: () => ({
            budget: {},
            price: {},
            groups: [],
            name: "",
            count: 0
        }),
        beforeMount() {
            this.collect();
        },
        watch:{
            summary(){
                this.collect();
            }
        },
        methods:{
            collect(){
                const {budget = {}, price = {}, groups = [], name = "", count = 0} = this.summary;
                this.budget = budget;
                this.price = price;
                this.groups = groups;
                this.name = name;
                this.count = count;
            },
            onCloseItem(){
                const {id} = this.summary
                this.$emit('onClose', id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-item{
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        position: relative;
        width: 210px;
        margin: 5px;
        overflow-y: auto;
        max-height: 326px;
        box-shadow: 2px 3px 20px rgba(59,74,116,.10196078431372549);

        &__title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            white-space: nowrap;
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__description{
            color: #AAB0B8;
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        &__container{
            box-sizing: border-box;
            margin-top: 8px;
            background: #FFFFFF;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 4px;
        }

        &__container-head{
            display: flex;
            padding: 8px;
        }

        &__container-body{
            background: #F4F5F5;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 0 0 4px 4px;
            padding: 8px 8px 4px 8px;
        }

        &__container-title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
        }

        &__container-value{
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height, or 120% */
            text-align: right;
            /* black */
            color: #000000;

            margin-left: auto;
        }

        &__btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: #AFB0B4;
            z-index: 4;
            transition: all .4s ease-out;

            &:hover, &:active {
                color: #000;

            }
        }
    }
</style>
