const SchemeLegend = {
    legendsDefault: {
        placement: {
            title: 'Легенда размещения',
            groups: [
                {
                    id: 'default',
                    value: 'По умолчанию'
                },
                {
                    id: 'type_id',
                    value: 'Тип'
                },
                {
                    id: 'supp_id',
                    value: 'Оператор'
                },
                {
                    id: 'kind_id',
                    value: 'Вид'
                },
                {
                    id: 'format_id',
                    value: 'Формат'
                },
                {
                    id: 'lighted',
                    value: 'Освещение'
                },
                {
                    id: 'side_name',
                    value: 'Сторона'
                },
                {
                    id: 'date',
                    value: 'Дата'
                },
                {
                    id: 'status',
                    value: 'Статус'
                },
                {
                    id: 'user_status',
                    value: 'Статус клиента'
                },
                {
                    id: 'layout',
                    value: 'Макеты'
                },
                {
                    id: 'project',
                    value: 'Проекты'
                }
            ],
            groupSelected: 'default',
            items: {}
        },
        offer: {
            title: 'Легенда предложения',
            groups: [
                {
                    id: 'default',
                    value: 'По умолчанию'
                },
                // {
                //     id: 'district_id',
                //     value: 'Округ'
                // },
                {
                    id: 'type_id',
                    value: 'Тип'
                },
                {
                    id: 'supp_id',
                    value: 'Оператор'
                },
                {
                    id: 'kind_id',
                    value: 'Вид'
                },
                {
                    id: 'format_id',
                    value: 'Формат'
                },
                {
                    id: 'lighted',
                    value: 'Освещение'
                },
                {
                    id: 'side_name',
                    value: 'Сторона'
                },
                {
                    id: 'date',
                    value: 'Дата'
                },
                {
                    id: 'status',
                    value: 'Статус'
                },
                {
                    id: 'user_status',
                    value: 'Статус клиента'
                },
                {
                    id: 'layout',
                    value: 'Макеты'
                },
                {
                    id: 'project',
                    value: 'Проекты'
                }
            ],
            groupSelected: 'default',
            items: {}
        },
    },
};

export default SchemeLegend;
