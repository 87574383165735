import ServiceApi from '../ServiceApi';
import ServiceFilter from '../ServicesFilter/ServiceFilter';
import SchemeKitPanel from '../../schemes/SchemeKitPanel';
const {kitTypes, kitTypesReverse} = SchemeKitPanel;

/** Сервис для работы с наборами на карте */
export default class ServiceMapKit {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} getKitList - маршрут для получения списка наборов
     * @property {String} getLegend - маршрут для получения легенды
     */
    static _requestRouts = {
        getKitList: '/main/price-group-list',
        getLegend: '/legend/groups',
        setLegendStyle: '/legend/edit-style',
        editKit: '/price_group/update',
        deleteKit: '/price_group/delete',
        endEdit: '/price_group/finish'
    };

    /**
     * Ключи типов наборов (размещения, предложения)
     * @private
     * @static
     * @type {Object}
     * @property {Number} placement - ключ для размещений
     * @property {Number} offer - ключ для предложений
     */
    static _kitTypes = {...kitTypes};

    static _kitTypesReverse = {...kitTypesReverse};

    // static _kitTypes = {
    //     placement: 1,
    //     offer: 2,
    //     layout: 3,
    //     project: 4,
    //     kit: 5,
    // };

    // static _kitTypesReverse = {
    //     1: "placement",
    //     2: "offer",
    //     3: "layout",
    //     4: "project",
    //     5: "kit",
    // };

    /**
     * Получить список размещений и предложений
     * @public
     * @static
     * @async
     * @param {Number} page - номер страницы
     * @param {Array<Object>} kitListPrev - список ранее загруженных наборов
     * @param {String} kitListPrev[].id - идентификатор набора
     * @param {String} kitListPrev[].name - название набора
     * @param {String|Number} kitListPrev[].count - колличество сторон набора
     * @param {String|Number} kitListPrev[].sum - сумма цен набора
     * @param {String} kitListPrev[].color - цвет набора
     * @param {Array<String>} kitListPrev[].actionsActive - идентификаторы активных действий набора
     * @param {Boolean} kitListPrev[].isGeneral - является ли набор главным
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
    static async getKitList(page = 0, kitListPrev = [], isTurnOnMarkers = false, actionAfter = () => {}) {
        const kitListPrevPlacements = this._getKitListByType(kitListPrev, 'placement');
        const kitListPrevOffers = this._getKitListByType(kitListPrev, 'offer');
        if (page === 0) {
            await this.getPlacements(page, kitListPrevPlacements, async (dataPlacements) => {
                const {kitList: placements = []} = dataPlacements;
                await this.getOffers(page, kitListPrevOffers, (dataOffers) => {
                    const {kitList: offers = [], pageCount: offersPageCount = 0} = dataOffers;
                    const dataKits = {
                        kitList: [...placements, ...offers],
                        pageCount: offersPageCount
                    };
                    if(dataKits.kitList.length > 0 && kitListPrevPlacements.length === 0 && isTurnOnMarkers){
                        dataKits.kitList[0].actionsActive.push("markers");
                    }
                    actionAfter(dataKits);
                });
            });
        }
        else
            await this.getOffers(page, kitListPrevOffers, (dataOffers) => actionAfter(dataOffers));

    }

    /**
     * Получить список размещений
     * @public
     * @static
     * @async
     * @param {Number} page - номер страницы
     * @param {Array<Object>} kitListPrevPlacements - список наборов размещений
     * @param {String} kitListPrevPlacements[].id - идентификатор набора
     * @param {String} kitListPrevPlacements[].name - название набора
     * @param {String|Number} kitListPrevPlacements[].count - колличество сторон набора
     * @param {String|Number} kitListPrevPlacements[].sum - сумма цен набора
     * @param {String} kitListPrevPlacements[].color - цвет набора
     * @param {Array<String>} kitListPrevPlacements[].actionsActive - идентификаторы активных действий набора
     * @param {Boolean} kitListPrevPlacements[].isGeneral - является ли набор главным
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
    static async getPlacements(page = 0, kitListPrevPlacements = [], actionAfter = () => {}) {
        await this._postKitList(page, kitListPrevPlacements, this._kitTypes.placement, [], (data = {}) => {
            const {kitList: placements = []} = data;
            const placementsBuff = placements.length > 0 ? [placements[0]] : [];
            if (placements.length > 0){
                placementsBuff.splice(0, 1, {...placementsBuff[0], isGeneral: true});
            }


            const dataAfter = {
                ...data,
                kitList: [...placementsBuff],
            }
            actionAfter(dataAfter);
        });
    }

    /**
     * Получить список предложений
     * @public
     * @static
     * @async
     * @param {Number} page - номер страницы
     * @param {Array<Object>} kitListPrevOffers - список наборов предложений
     * @param {String} kitListPrevOffers[].id - идентификатор набора
     * @param {String} kitListPrevOffers[].name - название набора
     * @param {String|Number} kitListPrevOffers[].count - колличество сторон набора
     * @param {String|Number} kitListPrevOffers[].sum - сумма цен набора
     * @param {String} kitListPrevOffers[].color - цвет набора
     * @param {Array<String>} kitListPrevOffers[].actionsActive - идентификаторы активных действий набора
     * @param {Boolean} kitListPrevOffers[].isGeneral - является ли набор главным
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
    static async getOffers(page = 0, kitListPrevOffers = [], actionAfter = () => {}) {
        await this._postKitList(page, kitListPrevOffers, this._kitTypes.offer, [], actionAfter);
    }

    static async getLayouts(page = 0, getListPrevLayouts = [], activeKitsIds, actionAfter = () => {}) {
        await this._getSidePanelList(page, getListPrevLayouts, "layout", activeKitsIds, actionAfter)
    }

    static async getProjects(page = 0, getListPrevProjects = [], activeKitsIds, actionAfter = () => {}) {
        await this._getSidePanelList(page, getListPrevProjects, "project", activeKitsIds, actionAfter)
    }

    static async getKits(page = 0, getListPrevKits = [], activeKitsIds, actionAfter = () => {}) {
        await this._getSidePanelList(page, getListPrevKits, "kit", activeKitsIds, actionAfter)
    }

    static async _getSidePanelList(page = 0, getListPrev = [], kitType = '', activeKitsIds, actionAfter = () => {}) {
        const kitListPrev = this._getKitListByType(getListPrev, kitType);
        await this._postKitList(page, kitListPrev, this._kitTypes[kitType], activeKitsIds, actionAfter);
    }

    static async getKitByType(kitType = -1, kitId = -1, actionAfter) {
        await this._postKitList(0, [], kitType, [kitId], actionAfter);
    }

    /**
     * Получить группу по id
     * @public
     * @static
     * @async
     * @param {Number} kitId - id набора
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
    static async getKit(kitId, actionAfter = () => {}) {
        await this._postKitItem(kitId, actionAfter);
    }

    /**
     * Получить легенду набора
     * @public
     * @static
     * @async
     * @param {String} kitTypeKey - ключ типа набора
     * @param {String} legendAttribute - атрибут легенды
     * @param {Array<String>} kitIds - id наборов
     * @param {Function} actionAfter - событие, сработающее после получения легенды
     */
    static async getKitLegend(kitTypeKey = '', legendAttribute = '', kitIds = [], actionAfter = () => {}) {
        await this._postKitLegend(kitTypeKey, legendAttribute, kitIds, actionAfter);
    }

    static async renameKit(kitItemNameNew = '', kitItemId = '', actionAfter = () => {}) {
        await this._putRenameKit(kitItemNameNew, kitItemId, actionAfter);
    }

    static async changeColorKit(newColor = '', kitItemId = '', actionAfter = () => {}) {
        await this._putChangeColorKit(newColor, kitItemId, actionAfter);
    }

    static async changeFigureKit(newFigure = '', kitItemId = '', actionAfter = () => {}) {
        await this._putChangeFigureKit(newFigure, kitItemId, actionAfter);
    }

    static async changeColorLegend(newColor = '', legendKey = '', group = '', legendItemId = '', actionAfter = () => {}) {
        await this._putChangeColorLegend(newColor, legendKey, group,legendItemId,actionAfter);
    }

    static async changeFigureLegend(newFigure = '', legendKey = '', group = '', legendItemId = '', actionAfter = () => {}) {
        await this._putChangeFigureLegend(newFigure, legendKey, group,legendItemId,actionAfter);
    }

    static async deleteKit(kitItemId = '', actionAfter = () => {}) {
        await this._deleteKit(kitItemId, actionAfter);
    }

    /**
     * Получить список наборов по типу
     * @param {Array<Object>} kitList - список наборов
     * @param {String} kitList[].id - идентификатор набора
     * @param {String} kitList[].name - название набора
     * @param {String|Number} kitList[].count - колличество сторон набора
     * @param {String|Number} kitList[].sum - сумма цен набора
     * @param {String} kitList[].color - цвет набора
     * @param {Array<String>} kitList[].actionsActive - идентификаторы активных действий набора
     * @param {Boolean} kitList[].isGeneral - является ли набор главным
     * @param {String} kitTypeKey - ключ типа набора (placement|offer)
     * @returns {Array<Object>} kitListByType - список наборов по типу
     */
    static _getKitListByType(kitList = [], kitTypeKey = '') {
        return kitList.filter(kitListItem => parseInt(kitListItem?.type) === this._kitTypes[kitTypeKey]);
    }

    /**
     * Сделать запрос на получения списка наборов
     * @private
     * @static
     * @async
     * @param {Number} page - номер страницы
     * @param {Array<Object>} kitListPrev - список ранее загруженных наборов
     * @param {String} kitListPrev[].id - идентификатор набора
     * @param {String} kitListPrev[].name - название набора
     * @param {String|Number} kitListPrev[].count - колличество сторон набора
     * @param {String|Number} kitListPrev[].sum - сумма цен набора
     * @param {String} kitListPrev[].color - цвет набора
     * @param {Array<String>} kitListPrev[].actionsActive - идентификаторы активных действий набора
     * @param {Boolean} kitListPrev[].isGeneral - является ли набор главным
     * @param {Number} kitType - ключ типа набора
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
     static async _postKitList(page = 0, kitListPrev = [], kitType = -1, activeKitsIds = [], actionAfter = () => {}) {
        const reqBody = this._postKitListBefore(page, kitType, activeKitsIds);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getKitList, reqBody);
            const {data = []} = res;
            const {items = [], pageCount = 0} = data;
            const dataAfter = this._postKitListAfter(items, kitListPrev, pageCount);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Сделать запрос на получение набора
     * @private
     * @static
     * @async
     * @param {Number} kitId - id набора
     * @param {Function} actionAfter - событие, сработающее после получения списка
     */
    static async _postKitItem(kitId = -1, actionAfter = () => {}) {
        const reqBody = this._postKitBefore(kitId);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getKitList, reqBody);
            const {data = []} = res;
            const {items = [], pageCount = 0} = data;
            const dataAfter = this._postKitListAfter(items, [], pageCount);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Сделать запрос на получение легенды
     * @private
     * @static
     * @async
     * @param {String} kitTypeKey - ключ типа набора
     * @param {String} legendAttribute - атрибут легенды
     * @param {Array<String>} kitIds - id наборов
     * @param {Function} actionAfter - событие, сработающее после получения легенды
     */
    static async _postKitLegend(kitTypeKey = '', legendAttribute = '', kitIds = [], actionAfter = () => {}) {
        const reqBody = this._postKitLegendBefore(kitTypeKey, legendAttribute, kitIds);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getLegend, reqBody);
            const {data = []} = res;
            const legendItemsAfter = this._postKitLegendAfter(kitTypeKey, legendAttribute, data);
            actionAfter(legendItemsAfter);
        } catch (error) {
            console.log(error);
        }
    }

    static async _putRenameKit(kitItemNameNew = '', kitItemId = '', actionAfter = () => {}) {
        const reqBody = this._putRenameKitBefore(kitItemNameNew, kitItemId);
        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.editKit, reqBody);
            actionAfter(kitItemNameNew, kitItemId);
        } catch (error) {
            console.log(error);
        }
    }

    static async _putChangeColorKit(newColor = '', kitItemId = '', actionAfter = () => {}) {
        const reqBody = this._putChangeColorKitBefore(newColor, kitItemId);
        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.editKit, reqBody);
            actionAfter(newColor, kitItemId);
        } catch (error) {
            console.log(error);
        }
    }

    static async _putChangeFigureKit(newFigure = '', kitItemId = '', actionAfter = () => {}) {
        const reqBody = this._putChangeFigureKitBefore(newFigure, kitItemId);
        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.editKit, reqBody);
            actionAfter(newFigure, kitItemId);
        } catch (error) {
            console.log(error);
        }
    }

    static async _putChangeColorLegend(
        newColor = '',
        legendKey = '',
        group = '',
        legendItemId = '',
        actionAfter = () => {}
    ) {
        const reqBody = this._putChangeColorLegendBefore(newColor, legendKey, group, legendItemId);
        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.setLegendStyle, reqBody);
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async _putChangeFigureLegend(
        newFigure = '',
        legendKey = '',
        group = '',
        legendItemId = '',
        actionAfter = () => {}
    ) {
        const reqBody = this._putChangeFigureLegendBefore(newFigure, legendKey, group, legendItemId);
        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.setLegendStyle, reqBody);
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async _deleteKit(kitItemId = '', actionAfter = () => {}) {
        try {
            await ServiceApi.delete(this._microserviceName, `${this._requestRouts.deleteKit}/${kitItemId}`);
            actionAfter(kitItemId);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для получения списка наборов
     * @private
     * @static
     * @param {Number} page - номер страницы для пагинации
     * @param {Number} kitType - ключ типа набора
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Number} reqBody.filter_main.price_group_type - ключ типа набора
     * @returns {Number} reqBody.page - номер страницы для пагинации
     */
    static _postKitListBefore(page = 0, kitType = -1, activeKitsIds = []) {
        const filterSelected = ServiceFilter.getFilterSelected();
        let reqBody = {
            filter_main: {
                main_params: { ...filterSelected },
                price_group_type: parseInt(kitType),
                price_group_id: activeKitsIds,
            },
            page: parseInt(page),
        };
        // if (kitId !== -1) {
        //     reqBody.filter_main.price_group_id = parseInt(kitId);
        // }

        return reqBody;
    }

    /**
     * Подготовить тело запроса для получения набора
     * @private
     * @static
     * @param {Number} kitId - ключ id набора
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Number} reqBody.filter_main.price_group_type - ключ типа набора
     * @returns {Number} reqBody.page - номер страницы для пагинации
     */
    static _postKitBefore(kitId = -1) {
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: parseInt(kitId)
            }
        };

        return reqBody;
    }

    /**
     * Подготовить тело запроса для получения легенды
     * @private
     * @static
     * @param {String} kitTypeKey - ключ типа набора
     * @param {String} legendAttribute - атрибут легенды
     * @param {Array<String>} kitIds - id наборов
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Array<Number>} reqBody.filter_main.price_group_id - id наборов
     * @returns {Number} reqBody.filter_main.price_group_type - ключ типа набора
     * @returns {Object} reqBody.legend - легенда
     * @returns {String} reqBody.legend.attribute - атрибут легенды
     */
    static _postKitLegendBefore(kitTypeKey = '', legendAttribute = '', kitIds = []) {
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: kitIds.map(kitId => parseInt(kitId)),
                price_group_type: parseInt(this._kitTypes[kitTypeKey] ?? -1)
            },
            legend: {
                attribute: String(legendAttribute),
                price_group_type: parseInt(this._kitTypes[kitTypeKey] ?? -1)
            }
        };

        return reqBody;
    }

    static _putRenameKitBefore(kitItemNameNew = '', kitItemId = '') {
        const reqBody = {
            price_group: {
                pg_id: parseInt(kitItemId),
                name: String(kitItemNameNew)
            }
        };

        return reqBody;
    }

    static _putChangeColorKitBefore(newColor = '', kitItemId = '') {
        const reqBody = {
            price_group: {
                pg_id: parseInt(kitItemId),
                color: String(newColor)
            }
        };

        return reqBody;
    }

    static _putChangeFigureKitBefore(newFigure = '', kitItemId = '') {
        const reqBody = {
            price_group: {
                pg_id: parseInt(kitItemId),
                figure: String(newFigure)
            }
        };

        return reqBody;
    }

    static _putChangeColorLegendBefore(newColor, legendKey, group, legendItemId) {
        const reqBody = {
            legend: {
                attribute: String(group),
                color: String(newColor),
                price_group_type: this._kitTypes[legendKey],
                item_id: legendItemId,
                // figure: 'circle'
            }
        };

        return reqBody;
    }

    static _putChangeFigureLegendBefore(newFigure, legendKey, group, legendItemId) {
        const reqBody = {
            legend: {
                attribute: String(group),
                figure: String(newFigure),
                price_group_type: this._kitTypes[legendKey],
                item_id: legendItemId,
                // figure: 'circle'
            }
        };

        return reqBody;
    }

    /**
     * Постобработать результат получения списка наборов
     * @private
     * @static
     * @param {Array<Object>} kitList - список наборов
     * @param {String} kitList[].id - идентификатор набора
     * @param {String} kitList[].name - название набора
     * @param {String|Number} kitList[].count - колличество сторон набора
     * @param {String|Number} kitList[].sum - сумма цен набора
     * @param {String} kitList[].color - цвет набора
     * @param {Number} kitList[].created_at - timestamp создания набора
     * @param {Number} kitList[].updated_at - timestamp обновления набора
     * @param {Array<String>} kitList[].actionsActive - идентификаторы активных действий набора
     * @param {Array<Object>} kitListPrev - список ранее загруженных наборов
     * @param {Number} pageCount - колличество страниц для пагинации
     * @returns {Object}
     */
    static _postKitListAfter(kitList = [], kitListPrev = [], pageCount = 0) {
        const kitListAfter = kitList.map(kit => {
            const {
                id = '',
                externalId = '',
                name = '',
                count = '',
                sum = '',
                color = '',
                figure = 'circle',
                type = '',
                created_at: createdAt = '',
                updated_at: updatedAt = '',
                is_shared: isShared = false,
                finished_at: finishedAt = null
            } = kit;

            const kitPrev = kitListPrev.find(kitPrev => String(kitPrev?.id) === String(id)) ?? {};
            const {actionsActive: actionsActivePrev = []} = kitPrev;

            const kitAfter = {
                id: String(id),
                externalId: String(externalId),
                name: String(name),
                count: String(count),
                sum: String(sum),
                color: `#${color}`,
                figure: figure,
                type: String(type),
                createdAt: String(createdAt),
                updatedAt: String(updatedAt),
                isShared: Boolean(isShared),
                finishedAt: finishedAt,
                isCanEndEdit: String(type) === '2',
                actionsActive: [...actionsActivePrev]
            };

            return kitAfter;
        });

        const dataAfter = {
            kitList: [...kitListAfter],
            pageCount: parseInt(pageCount)
        };

        return dataAfter;
    }

    static postKitListAfter(kitList = [], kitListPrev = [], pageCount = 0) {
        return this._postKitListAfter(kitList, kitListPrev, pageCount)
    }
    /**
     * Постобработать результат получения легенды
     * @param {String} kitTypeKey - ключ типа набора
     * @param {String} legendAttribute - атрибут легенды
     * @param {Array<Object>} legendItems - элементы легенды
     * @param {String} legendItems[].id - id элемента легенды
     * @param {String} legendItems[].value - значение элемента легенды
     * @param {Number} legendItems[].count - колличество элемента легенды
     * @param {Object} legendItems[].data - данные элемента легенды
     * @param {String} legendItems[].data.color - цвет элемента легенды
     * @returns {Array<Object>} legendItemAfter - данные после постобработки
     */
    static _postKitLegendAfter(kitTypeKey = '', legendAttribute = '', legendItems = []) {
        const legendItemsAfter = legendItems.map(legendItem => {
            const {
                id = '',
                value = '',
                count = 0,
                data: {
                    color = '',
                    figure = 'circle'
                } = {}
            } = legendItem;
            const legendItemAfter = {
                id: `${kitTypeKey}--${legendAttribute}--${id}`,
                title: String(value),
                color: String(color)[0] === '#' ? String(color) : `#${color}`,
                figure: figure,
                count: parseInt(count),
                active: true
            };

            return legendItemAfter;
        });

        return legendItemsAfter;
    }

    static async postEndEdit(kitItemId = '', actionAfter = () => {}, actionError = () => {}) {
        try {
            await ServiceApi.post(this._microserviceName, `${this._requestRouts.endEdit}/${kitItemId}`);
            actionAfter(kitItemId);
        } catch (error) {
            console.log(error);
            actionError();
        }
    }
}
