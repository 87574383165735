<template>
	<div class="all">
		<AppTable
			primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="true"
			:columns="columns"
			:rows="filteredRows"
			:isProgressBar="isProgressBarLoading"
            :isStickyProgressBar="true"
            :rowsSelected="rowsSelected"
            :rowsSelectedPart="rowsSelectedPart"
            :is-virtual-scroll="true"
            :virtual-scroll-config="getScrollConfig"
			class="all-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
            @onClickCell="onClickCell"
            @onScroll="onScrollTable"
		>
            <template
				v-for="(col, index) of columnsDropdown"
				v-slot:[col.prop]="scopeData"
			>
                <BaseSelect
                    ref="BaseSelect"
                    :key="index"
                    :isFixed="true"
                    :isVirtualScroll="true"
                    :isDisabled="getCellStatusIsDisabled(scopeData)"
                    :options="getCellStatusItems(scopeData)"
                    :value="getCellStatusValue(scopeData)"
                    :defaultTitle="getCellStatusDefaultTitle(scopeData)"
                    :tagColor="getCellStatusColor(scopeData)"
                    :style="{'max-width': '190px', 'width': '190px'}"
                    @check="onSelectStatus($event, scopeData)"
                />
            </template>
			<template
				v-for="(col, x) of [...modifieredCols, ...datesCols]"
				v-slot:[col]="scopeData"
			>
				<BaseCell
                    v-if="modifieredCols.includes(col)"
					:key="x"
					:content="scopeData.row[col]"
                    :short-content="baseCellContent(scopeData.row[col], scopeData.column.maxLength)"
                    :is-show-tooltip="showTooltip(col, scopeData.row[col], scopeData.column.maxLength)"
				/>
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                    class="cell-date-wrap"
                >
                    <label class="checkbox-label">
						<div class="cell-checkbox">
							<base-checkbox-v2
								:key="x"
								:checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
								@change.native="onCheckSubCheckbox({row: scopeData.row, column: scopeData.column})"
							/>
						</div>
						<div
							class="cell-checkbox2"
							:class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : getActualityColor({row: scopeData.row, column: scopeData.column}),
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB'
                            }"
						>
							<span>

                                {{scopeData.row[col] | cutNumber}}
                            </span>

						</div>
					</label>
                </div>
			</template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
                    class="all-table__photo"
				/>
			</template>

            <template v-slot:photo="scopeData">
                <AppPhoto
                    :small="scopeData.row.photo.small"
                    :big="scopeData.row.photo.big"
                    class="all-table__photo"
                />
            </template>

			<template v-slot:light="scopeData">
				<AppLight
                    :iconName="scopeData.row.light == 1 ? 'icon-light' : 'icon-light-no'"
                    :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

            <template v-slot:tax="scopeData">
                <div class="cell-tax">
                    {{scopeData.row.tax == '1' ? 'Да' : 'Нет'}}
                </div>
            </template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.row.image_link" />
			</template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>

            <template v-slot:more_btn="scopeData">
                <div class="cell-freeze">
                    <BaseDropdown
                        class="cell-dropdown"
                        :padding="0"
                        :is-fixed="false"
                        position="right"
                        :style-list="dropdownStyles"
                        @changeVisible="onChangeVisibleDropdown"
                    >
                        <template #preview>
                            <BaseButtonIcon
                                class="table-dropdown-button cell-dropdown__prev-btn"
                                iconName="tabler-icon-dots-vertical"
                                tooltipText=""
                            />
                        </template>

                        <template #content v-if="isShowDropdown">
                            <div class="cell-dropdown__container">
                                <BaseButton
                                    v-if="folderId === '0'"
                                    view="simple"
                                    form="oval"
                                    size="small"
                                    @click="onAddPricesRow({ row: scopeData.row, column: scopeData.column })"
                                >Добавить</BaseButton>
                                <BaseButton
                                    v-else
                                    view="simple"
                                    form="oval"
                                    size="small"
                                    @click="onRemovePricesRow({ row: scopeData.row, column: scopeData.column })"
                                >Удалить</BaseButton>
                            </div>
                        </template>
                    </BaseDropdown>
                </div>
            </template>
		</AppTable>
	</div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";
import BaseSelect from '../Base/BaseSelect.vue';
import ServiceUser from '../../services/ServicesAuth/ServiceUser';

export default {
	name: "KitTab",
    mixins: [MixinHandbookTab],
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BaseCheckboxV2,
        BaseButton,
        BaseDropdown,
        BaseButtonIcon,
        BaseSelect
	},
	props: {
        datesCols: {
            type: Array,
			default: () => ([])
        },
        rowsSelected: {
            type: Array,
			default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
		folderId: {
			type: [Number, String],
			required: true
		},
        canCheck: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 0
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        kitStatusColors: {
            type: Object,
            default: () => ({})
        }
	},
    data: () => ({
        scrollTop: 0,
        rowHeight: 40,
        isShowDropdown: false,
        dropdownStyles: {
            minWidth: '100px',
            top: '0',
            right: '45px',
            boxShadow: '0 0 10px 0 #aaa',
        },
    }),
    computed: {
        columnsDropdown() {
            return this.columns.filter(column => column.isDropdownColumn ?? false);
        },
        filteredRows() {
            if (this.isVirtualScroll && this.tableData.length > 0) {
                return this.tableData.slice(this.nodeIndexStart, this.nodeIndexEnd);
            }

            return this.tableData;
        },
        tableBodyHeight() {
            const tableHeader = 58;
            return this.height > 0 ? this.height - tableHeader : 0;
        },
        nodeIndexStart() {
            const visibleRows = Math.ceil(this.tableBodyHeight / this.rowHeight);
            const nodeIndexStart = Math.floor(this.scrollTop / this.rowHeight) - (visibleRows * 3);

            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const visibleRows = Math.ceil(this.tableBodyHeight / this.rowHeight);
            const nodeIndexEnd = this.nodeIndexStart + visibleRows * 5;

            return Math.min(nodeIndexEnd, this.tableData.length);
        },
        spacerTopStyle() {
            return `${this.nodeIndexStart * this.rowHeight}px`;
        },
        spacerBottomStyle() {
            return `${(this.tableData.length - this.nodeIndexEnd) * this.rowHeight}px`;
        },
        getScrollConfig() {
            if (!this.isVirtualScroll) {
                return {};
            }

            return {
                rowHeight: this.rowHeight,
                spacerStyle: {
                    'padding-top': this.spacerTopStyle,
                    'padding-bottom': this.spacerBottomStyle
                }
            };
        },
    },
    methods: {
        onSelectStatus(status, scopeData) {
            const {cell = {}, row = {}, column = {}} = scopeData;
            const {extraData = {}} = cell;
            const {prop = ''} = column;
            const {attribute = '', entity: entityName = '', priceId = ''} = extraData;
            const {id = ''} = status;

            const statusSelectedData = {
                entityName: String(entityName),
                attribute: String(attribute),
                priceId: String(priceId),
                newValue: String(id),
                rowData: {...row},
                columnProp: String(prop)
            };

            this.$emit('onSelectStatus', statusSelectedData);
        },
        getCellStatusValue(scopeData = {}) {
            const {cell: {value = ''} = {}} = scopeData;
            return String(value);
        },

        getCellStatusDefaultTitle(scopeData = {}) {
            const {cell: {defaultTitle = ''} = {}} = scopeData;
            return String(defaultTitle);
        },
        getCellStatusIsDisabled(scopeData = {}) {
            const isDisabledByPriceGroupType = scopeData?.row?.extraData?.prices?.filter(price => String(price?.priceGroup?.type ?? '') === '1')?.length > 0;

            const statusPermissions = {
                'status': 'agency',
                'user_status': 'client'
            };
            const attribute = String(scopeData?.cell?.extraData?.attribute ?? '');
            const userPermissions = ServiceUser.profile?.extraData?.permissions ?? {};
            const isDisabledByUserPermissions = userPermissions[statusPermissions[attribute] ?? ''] ? false : true;

            return isDisabledByPriceGroupType || isDisabledByUserPermissions;
        },
        getCellStatusItems(scopeData = {}) {
            const {cell: {items = []} = {}} = scopeData;
            return items;
        },
        getCellStatusColor(scopeData = {}) {
            const {cell: {extraData: {attribute = ''} = {}, value = ''} = {}} = scopeData;
            const statusColorsItems = this.kitStatusColors[attribute] ?? [];
            const {color = ''} = statusColorsItems.find(statusColorsItem => String(statusColorsItem?.id) === String(value)) ?? {};
            return String(color);
        },
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        onClickCell(data){
            data.kitId = this.folderId;
            this.$emit('onClickCell', data);
        },
        onCheckSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {allPrices = null, prices = []} = row;
            const {prop = ''} = column;
            const propSplitted = String(prop).split('__');
            const propDate = propSplitted[0];
            const currentPrices = allPrices === null ? Object.assign([], prices) : Object.assign([], allPrices);
            const price = currentPrices.find(price => String(price.date) === String(propDate)) || {};
            if(Object.keys(price).length){
                this.$emit('onCheckSubCheckbox', checkData);
            }

        },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxes[rowId] || [];
            return cellsChecked.includes(prop);
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null && !isNaN(price);
        },
        onScrollTable(event) {
            // сделать emit
            if (this.isVirtualScroll) {
                const {currentTarget = {}} = event;
                const {scrollTop = 0} = currentTarget;
                this.scrollTop = scrollTop;
            }

            this.$refs.BaseSelect.forEach(BaseSelectComponent => {
                BaseSelectComponent.onClose();
            });
        },
        onAddPricesRow(checkData) {
            this.isShowDropdown = false;
            this.$emit('onAddPricesRow', checkData);
        },
        onRemovePricesRow(checkData) {
            this.isShowDropdown = false;
            this.$emit('onRemovePricesRow', checkData);
        },
        onChangeVisibleDropdown(newVal) {
            this.isShowDropdown = newVal;
        },
        showTooltip(col, val, maxLength = 0) {
            const isSlicedString = this.hasSlicedBaseCell(val, maxLength);
            const slicedString = this.baseCellContent(val, maxLength);

            // 3 - троеточие в конце не считаем в длинну строки
            const slicedLength = isSlicedString ? slicedString.length - 3 : slicedString.length;
            return (
                (val !== null) &&
                (col === 'address' || col === 'gid') &&
                (val.length > slicedLength)
            );
        },
        baseCellContent(content, maxLength = 0) {
            if (content !== null && maxLength && (content.length > maxLength)) {
                const sliced = content.slice(0, maxLength);
                const result = `${sliced}...`;
                return result;
            }

            return content !== null ? String(content) : '';
        },
        hasSlicedBaseCell(content, maxLength = 0) {
            return content !== null && maxLength && (content.length > maxLength);
        },
    },
	filters: {
		cutNumber(value = '') {
            const price = (value !== '' && value !== null && !isNaN(value)) ? value : '';
            if (price !== '') {
                const priceLocale = parseInt(price).toLocaleString('ru');
                // const priceLocaleArr = String(priceLocale).split(/\s/);
                // const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                // return priceFormatted;
                return priceLocale;
            }
		}
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;

.all {
	display: flex;

	&-table {
		margin-top: 4px;

        &__photo {
            width: 34px;
            height: 34px;
        }

        ::v-deep .header {
            .format-cell .cell__content span,
            .ots-cell .cell__content span,
            .grp-cell .cell__content span {
                text-align: right !important;
            }

            .cell.date-cell {
                padding: 4px 15px !important;

                & span {
                    text-align: right !important;
                }
            }
        }

        ::v-deep .body {
            .cell.address-cell .default-cell,
            .cell.direction-cell .default-cell,
            .cell.material-cell .default-cell {
                height: inherit;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }

            .cell.address-cell .default-cell {
                word-break: break-all;
            }
        }
	}

    .cell-date-wrap {
        width: 100%;
        height: 100%;
    }

    .cell-tax{
        width: 100%;
        text-align: center;
    }

	.checkbox-label {
		.cell-checkbox { display: none !important; }

		.cell-checkbox2 {
            width: 100%;
            height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;


			&_active {
				background: $active-color;
				border-color: $active-color;
				color: #fff;
			}
		}
	}

    .cell-dropdown {
        ::v-deep .dropdown__triangle {
            top: 12px !important;
            right: -18px !important;
            border-left: 9px solid #fff !important;
            border-bottom-color: transparent !important;
            z-index: 6 !important;
        }

        ::v-deep .dropdown {
            box-shadow: 0 3px 15px #21242d17 !important;
        }

        &__container {
            position: relative;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 5;
            padding: 10px;

            button {
                padding: 5px 10px;
                font-weight: 500;
                color: #ADB0BB;
                transition: all .4s ease-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }
}
</style>
